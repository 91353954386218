<template>
  <b-sidebar
    id="edit-car-active"
    :visible="isEditCarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    :width="sidebarMinWidth"
    shadow
    backdrop
    no-header
    right
    @hidden="resetCarData"
    @abort="resetCarData"
    @change="(val) => $emit('update:is-edit-car-active', val)"
  >
    <!-- :style="`min-width:${sidebarMinWidth}`" -->
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5
          class="mb-0"
        >
          Fahrzeug Detail
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <div
        :style="`padding-left:${bodyPadding}px; padding-right:${bodyPadding}px;`"
      >
        <validation-observer
          #default="{ handleSubmit }"
          ref="refEditCarFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <h6>Fahrzeug</h6>
            <hr>
            <b-form-group
              label="Marke und Modell"
              label-for="title"
            >
              <validation-provider
                #default="{ errors }"
                name="Marke und Modell"
                rules=""
              >
                <b-form-input
                  id="title"
                  v-model="carData.carTitle"
                  :disabled="disableField('carTitle')"
                  trim
                  placeholder=""
                />
                <small class="text-warning">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              :label="getLabel('vin')"
              label-for="vin"
            >
              <validation-provider
                #default="{ errors }"
                name="Fahrzeugidentifikationsnummer"
                rules="required|length:17|alpha-num"
              >
                <!-- :disabled="carData.currentStatus >= 30" -->
                <b-form-input
                  id="vin"
                  v-model="carData.vin"
                  :disabled="disableField('vin')"
                  trim
                  placeholder=""
                />
                <small class="text-warning">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-tooltip
              target="Steckertyp-edit-tooltip"
              title="
                      Typ 1 ist bei amerikanischen Fahrzeugen üblich, er ist ein Einphasenstecker und kann mit einer Geschwindigkeit von bis zu 7,4 kW aufladen.
                      Typ 2 ist Standard für europäische und asiatische Fahrzeuge ab 2018. Er ist ein dreiphasiger Stecker und kann mit einer Leistung von bis zu 43 kW aufladen.
                      CCS ist eine Version des Typs 2 mit zwei zusätzlichen Stromkontakten. Er ermöglicht ein sehr schnelles Laden.
                      CHAdeMO ist in asiatischen Autos zu finden und ermöglicht sowohl hohe Ladekapazitäten als auch bidirektionales Laden.
                    "
            />
            <b-form-group>
              <label
                for="Steckertyp"
                class="d-block"
              >
                Steckertyp
                <feather-icon
                  id="Steckertyp-edit-tooltip"
                  icon="InfoIcon"
                  size="15"
                />
              </label>
              <b-form-select
                v-model="carData.connectorType"
                :options="connectorTypes"
                :disabled="disableField('connectorType')"
              />
            </b-form-group>


            <b-form-group
              label="Nutzung"
              label-for="useType"
            >
              <b-form-select
                v-model="carData.useType"
                :options="useTypes"
                :disabled="disableField('useType')"
              />
            </b-form-group>
            <b-form-group
              :label="getLabel('address')"
              label-for="address"
            >
              <validation-provider
                #default="{ errors }"
                name="Ladeadresse"
                rules="required"
              >
                <b-form-select
                  v-model="carData.address"
                  :options="addressList"
                  :disabled="disableField('address')"
                />
                <small class="text-warning">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div
              v-if="carData.address==-1"
            >
              <b-form-group
                label="Strasse + Nummer*"
                label-for="addressStreet"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Strasse + Nummer"
                  rules="required"
                >
                  <b-form-input
                    id="addressStreet"
                    v-model="carData.addressStreet"
                    trim
                    placeholder=""
                  />
                  <small class="text-warning">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Postleitzahl*"
                label-for="addressZip"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Postleitzahl"
                  rules="required"
                >
                  <b-form-input
                    id="addressZip"
                    v-model="carData.addressZip"
                    trim
                    placeholder=""
                  />
                  <small class="text-warning">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Ort*"
                label-for="addressCity"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Ort"
                  rules="required"
                >
                  <b-form-input
                    id="addressCity"
                    v-model="carData.addressCity"
                    trim
                    placeholder=""
                  />
                  <small class="text-warning">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <b-form-group>
              <label
                for="Ladestelleninfo"
                class="d-block"
              >
                Infofeld: Bonus / Rabattcode / Kundennummer / etc.
                <feather-icon
                  id="extraCharhingInfo-tooltip"
                  icon="InfoIcon"
                  size="15"
                />
              </label>
              <b-form-input
                id="extraCharhingInfo"
                v-model="carData.extraCharhingInfo"
                :disabled="disableField('extraCharhingInfo')"
                trim
                placeholder=""
              />
            </b-form-group>

            <b-form-group
              :label="getLabel('permit_side_a')"
              label-for="Zulassungsschein"
            >
              <div
                v-if="!carPermitImages['permit_side_a'].id && carData.currentStatus <= 15"
              >
                <file-pond
                  ref="pondAEdit"
                  name="fileUpload"
                  label-idle="Wählen Sie eine Datei oder ziehen Sie es ins Feld Unterstützte. Dateiformate: JPG, PNG - Max Größe: 20MB"
                  allow-replace="true"
                  allow-revert="true"
                  accepted-file-types="image/jpeg, image/png"
                  :server="{
                    url: apiUrl,
                    process: {
                      url: `/file-repository/change/${tempFormUUID}/permit_side_a/${props.userIdProp}/${carData.bonusId}`,
                      method: 'POST',
                      headers: {
                        'Authorization': `Bearer ${accessToken}`
                      },
                    },
                    revert: {
                      url: `/file-repository/revert/${tempFormUUID}/permit_side_a/${props.userIdProp}`,
                      method: 'DELETE',
                      headers: {
                        'Authorization': `Bearer ${accessToken}`
                      },
                    },
                  }"
                  allow-file-encode="true"
                  allow-file-rename="true"
                  allow-image-resize="true"
                  image-resize-target-width="1280"
                  image-resize-target-height="720"
                  image-resize-mode="cover"
                  :file-rename-function="fileRenameFunction"
                  @processfile="processFile"
                  @removefile="removeFile"
                />
              </div>
              <div
                v-if="carPermitImages['permit_side_a'].id"
              >
                <b-button
                  variant="outline-secondary"
                  block
                  @click="previewImage(carPermitImages['permit_side_a'].id, carPermitImages['permit_side_a'].userId, carPermitImages['permit_side_a'].fileContentType)"
                >
                  <feather-icon icon="CameraIcon" />
                  Zulassungsschein Vorderseite vorschau
                </b-button>
                <div
                  v-if="!disableField('permit_side_a')"
                  style="text-align:right; color:#82868b;"
                >
                  <a
                    nohref
                    @click="deleteImage(carPermitImages['permit_side_a'].id)"
                  >
                    Vorderseite löschen
                  </a>
                </div>
              </div>
            </b-form-group>
            <b-form-group
              :label="getLabel('permit_side_b')"
              label-for="Zulassungsschein"
            >
              <div
                v-if="!carPermitImages['permit_side_b'].id && carData.currentStatus <= 15"
              >
                <file-pond
                  ref="pondBEdit"
                  name="fileUpload"
                  label-idle="Wählen Sie eine Datei oder ziehen Sie es ins Feld Unterstützte. Dateiformate: JPG, PNG - Max Größe: 20MB"
                  allow-replace="true"
                  allow-revert="true"
                  accepted-file-types="image/jpeg, image/png"
                  :server="{
                    url: apiUrl,
                    process: {
                      url: `/file-repository/change/${tempFormUUID}/permit_side_b/${props.userIdProp}/${carData.bonusId}`,
                      method: 'POST',
                      headers: {
                        'Authorization': `Bearer ${accessToken}`
                      },
                    },
                    revert: {
                      url: `/file-repository/revert/${tempFormUUID}/permit_side_b/${props.userIdProp}`,
                      method: 'DELETE',
                      headers: {
                        'Authorization': `Bearer ${accessToken}`
                      },
                    },
                  }"
                  allow-file-encode="true"
                  allow-file-rename="true"
                  allow-image-resize="true"
                  image-resize-target-width="1280"
                  image-resize-target-height="720"
                  image-resize-mode="cover"
                  :file-rename-function="fileRenameFunction"
                  @processfile="processFile"
                  @removefile="removeFile"
                />
              </div>
              <div
                v-if="carPermitImages['permit_side_b'].id"
              >
                <b-button
                  variant="outline-secondary"
                  block
                  @click="previewImage(carPermitImages['permit_side_b'].id, carPermitImages['permit_side_b'].userId, carPermitImages['permit_side_b'].fileContentType)"
                >
                  <feather-icon icon="CameraIcon" />
                  Zulassungsschein Rückseite vorschau
                </b-button>
                <div
                  v-if="!disableField('permit_side_b')"
                  style="text-align:right; color:#82868b;"
                >
                  <a
                    nohref
                    @click="deleteImage(carPermitImages['permit_side_b'].id)"
                  >
                    Rückseite löschen
                  </a>
                </div>
              </div>
            </b-form-group>

            <validation-provider
              #default="validationContext"
              name="Startzeitpunkt"
              rules="required"
            >
              <b-form-group
                :label="getLabel('dateFrom')"
                label-for="firstName"
              >
                <b-form-datepicker
                  v-model="carData.dateFrom"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  :disabled="disableField('dateFrom')"
                  locale="de"
                  :min="minDate"
                  :max="maxDate"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Endzeitpunkt"
              rules="required"
            >
              <b-form-group
                :label="getLabel('dateTo')"
                label-for="lastName"
              >
                <b-form-datepicker
                  v-model="carData.dateTo"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  :disabled="disableField('dateTo')"
                  locale="de"
                  :min="minDate"
                  :max="maxDate"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <br>
            <h6>Ladestelle</h6>
            <hr>
            <b-form-radio-group
              stacked
              @change="processFile"
            >
              <b-form-radio
                v-model="carData.flatRate"
                name="flatrate"
                :value="true"
              >
                <small>Ich bestätige hiermit, dass <b>eine nachweisliche Messung nicht möglich</b> ist, da der Nachweis einer infrastrukturseitigen Messung auf Ladepunktebene mittels MID konformer Stromzähler (EU-Messgeräterichtlinie, Measurement Instruments Directive, RL 2014/32/EU) oder gleichwertigen bzw. besseren Zählers (z.B. Zähler entsprechend dem deutschen Mess- und Eichrecht - ME-Zähler) zu erbringen ist und nachvollziehbare Aufzeichnungen von Einzelladevorgängen (automatisierte, produktseitige und nicht manipulierbare Aufzeichnungen der Ladestation), welche die an elektrisch betriebene Kraftfahrzeuge abgegebene energetische Menge in kWh dokumentieren, nicht vorhanden sind.</small>
              </b-form-radio>
              <br>
              <b-form-radio
                v-model="carData.flatRate"
                name="flatrate"
                :value="false"
              >
                <small><b>Ich messe</b> die Energie, die in mein Fahrzeug geladen wird <b>in gesetzlich geforderter nachweislicher Form</b> (MID konformer Zähler in der Ladestation, einzelne Ladevorgänge) und kann eine entsprechende Dokumentation zur Verfügung stellen.</small>
              </b-form-radio>
            </b-form-radio-group>
            <br>
            <b-tooltip
              target="powerUsed-tooltip"
              title="Wenn am Ladepunkt gemessen und nachvollziehbar überprüfbar aufgezeichnet wird, muss gemäß KVO §11 die jährliche Strommenge (in kWh) angegeben werden und mit Jahresende die Angabe auf den gemessenen Wert korrigiert werden. Es kann sein, dass sich dadurch auch die Höhe des Bonus ändert."
            />
            <b-form-group
              v-show="carData.flatRate==false"
            >
              <label
                for="powerUsed"
                class="d-block"
              >
                Geladene Strommenge [kWh]*
                <feather-icon
                  id="powerUsed-tooltip"
                  icon="InfoIcon"
                  size="15"
                />
              </label>
              <validation-provider
                #default="{ errors }"
                name="Geladene Strommenge [kWh]"
                rules="required|positive"
              >
                <cleave
                  id="powerUsed"
                  v-model="carData.powerUsed"
                  placeholder="1.500,00"
                  :options="options.number"
                  class="form-control"
                  trim
                  :disabled="disableField('powerUsed')"
                />
                <small class="text-warning">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-tooltip
              target="extraCharhingInfo-tooltip"
              title="Falls für Ihre interne Verwaltung von Interesse, zB bei mehreren Ladestellen an einer Adresse."
            />
            <b-form-group
              v-show="carData.flatRate==false"
            >
              <label
                for="chargingStationId"
                class="d-block"
              >
                Seriennummer Ladestelle*
                <feather-icon
                  id="chargingStation-tooltip"
                  icon="InfoIcon"
                  size="15"
                />
              </label>
              <validation-provider
                #default="{ errors }"
                name="Seriennummer Ladestelle"
                rules="required|alpha-num"
              >
                <b-form-input
                  id="chargingStationId"
                  v-model="carData.chargingStationId"
                  trim
                  placeholder=""
                  :disabled="disableField('chargingStationId')"
                />
                <small class="text-warning">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-tooltip
              target="chargingStation-tooltip"
              title="Die Seriennummer finden Sie zB auf dem Typenschild der Ladestation. Nur eine Seriennummer angeben. Wenn Sie mehrere Fahrzeuge an mehreren nicht-öffentlich zugänglichen Ladestellen laden, kontaktieren Sie uns bitte."
            />
            <b-form-group
              v-if="carData.flatRate==false"
            >
              <label
                for="chargingStationManufacturer"
                class="d-block"
              >
                Ladestelle Hersteller*
              </label>

              <validation-provider
                #default="{ errors }"
                name="Ladestelle Hersteller"
                rules="required|alpha-num"
              >
                <b-form-input
                  id="chargingStationManufacturer"
                  v-model="carData.chargingStationManufacturer"
                  trim
                  placeholder=""
                  :disabled="disableField('chargingStationManufacturer')"
                />
                <small class="text-warning">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="carData.flatRate==false"
            >
              <label
                for="chargingStationPorts"
                class="d-block"
              >
                Anzahl der Ladepunkte*
              </label>

              <validation-provider
                #default="{ errors }"
                name="Anzahl der Ladepunkte"
                rules="required|integer"
              >
                <b-form-input
                  id="chargingStationPorts"
                  v-model="carData.chargingStationPorts"
                  trim
                  placeholder="1"
                  :disabled="disableField('chargingStationPorts')"
                />
                <small class="text-warning">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="carData.flatRate==false"
            >
              <label
                for="chargingStationPower"
                class="d-block"
              >
                Ladeleistung [kW]*
              </label>

              <validation-provider
                #default="{ errors }"
                name="Ladeleistung"
                rules="required|positive"
              >
                <b-form-input
                  id="chargingStationPower"
                  v-model="carData.chargingStationPower"
                  trim
                  placeholder=""
                  :disabled="disableField('chargingStationPower')"
                />
                <small class="text-warning">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <hr v-if="carData.flatRate==false">
            <h6 v-if="carData.flatRate==false">
              ACHTUNG Sie können nur nachvollziehbare Strommengen dokumentieren wenn Sie Folgendes bereitstellen können:
            </h6>
            <!-- MID -->
            <b-form-group
              v-if="carData.flatRate==false"
              label="MID Konformität*"
              label-for="MID"
            >
              <small style="color: #5e5873;">
                ein Foto, dass das Typenschild der Ladestation zeigt, auf dem die Seriennummer und die MID Kennzeichnung gut lesbar sind. (keine Subzähler für die Ladestation!)
              </small>
              <div
                v-if="!carPermitImages['mid'].id && carData.currentStatus <= 15"
              >
                <file-pond
                  ref="pondCEdit"
                  name="fileUpload"
                  label-idle="Wählen Sie eine Datei oder ziehen Sie es ins Feld. Unterstützte Dateiformate: JPG, PNG - Max Größe: 20MB"
                  allow-replace="true"
                  allow-revert="true"
                  accepted-file-types="image/jpeg, image/png"
                  :server="{
                    url: apiUrl,
                    process: {
                      url: `/file-repository/change/${tempFormUUID}/mid/${props.userIdProp}/${carData.bonusId}`,
                      method: 'POST',
                      headers: {
                        'Authorization': `Bearer ${accessToken}`
                      },
                    },
                    revert: {
                      url: `/file-repository/revert/${tempFormUUID}/mid/${props.userIdProp}`,
                      method: 'DELETE',
                      headers: {
                        'Authorization': `Bearer ${accessToken}`
                      },
                    },
                  }"
                  allow-file-encode="true"
                  allow-file-rename="true"
                  allow-image-resize="true"
                  image-resize-target-width="1280"
                  image-resize-target-height="720"
                  image-resize-mode="cover"
                  :file-rename-function="fileRenameFunction"
                  @processfile="processFile"
                  @removefile="removeFile"
                />
              </div>
              <div
                v-if="carPermitImages['mid'].id"
              >
                <b-button
                  variant="outline-secondary"
                  block
                  @click="previewImage(carPermitImages['mid'].id, carPermitImages['mid'].userId, carPermitImages['mid'].fileContentType)"
                >
                  <feather-icon
                    v-if="carPermitImages['mid'].fileContentType == 'application/pdf'"
                    icon="FileTextIcon"
                  />
                  <feather-icon
                    v-else
                    icon="CameraIcon"
                  />
                  MID Konformität vorschau
                </b-button>
                <div
                  v-if="!disableField('mid')"
                  style="text-align:right; color:#82868b;"
                >
                  <a
                    nohref
                    @click="deleteImage(carPermitImages['mid'].id)"
                  >
                    MID Konformität löschen
                  </a>
                </div>
              </div>
            </b-form-group>
            <!-- best -->
            <b-form-group
              v-if="carData.flatRate==false"
              label="Installationsnachweis*"
              label-for="herstellerbestätigungInstallation"
            >
              <small style="color: #5e5873;">
                einen Installationsnachweis (zB eine Förderabrechnung, eine Abnahmebestätigung) mit Verweis auf die Seriennummer der Ladestation
              </small>
              <div
                v-if="!carPermitImages['instalation_verification'].id && carData.currentStatus <= 15"
              >
                <file-pond
                  ref="pondDEdit"
                  name="fileUpload"
                  label-idle="Wählen Sie eine Datei oder ziehen Sie es ins Feld. Unterstützte Dateiformate: JPG, PNG - Max Größe: 20MB"
                  allow-replace="true"
                  allow-revert="true"
                  accepted-file-types="image/jpeg, image/png"
                  :server="{
                    url: apiUrl,
                    process: {
                      url: `/file-repository/change/${tempFormUUID}/instalation_verification/${props.userIdProp}/${carData.bonusId}`,
                      method: 'POST',
                      headers: {
                        'Authorization': `Bearer ${accessToken}`
                      },
                    },
                    revert: {
                      url: `/file-repository/revert/${tempFormUUID}/instalation_verification/${props.userIdProp}`,
                      method: 'DELETE',
                      headers: {
                        'Authorization': `Bearer ${accessToken}`
                      },
                    },
                  }"
                  allow-file-encode="true"
                  allow-file-rename="true"
                  allow-image-resize="true"
                  image-resize-target-width="1280"
                  image-resize-target-height="720"
                  image-resize-mode="cover"
                  :file-rename-function="fileRenameFunction"
                  @processfile="processFile"
                  @removefile="removeFile"
                />
              </div>
              <div
                v-if="carPermitImages['instalation_verification'].id"
              >
                <b-button
                  variant="outline-secondary"
                  block
                  @click="previewImage(carPermitImages['instalation_verification'].id, carPermitImages['instalation_verification'].userId, carPermitImages['instalation_verification'].fileContentType)"
                >
                  <feather-icon
                    v-if="carPermitImages['instalation_verification'].fileContentType == 'application/pdf'"
                    icon="FileTextIcon"
                  />
                  <feather-icon
                    v-else
                    icon="CameraIcon"
                  />
                  Installationsnachweis vorschau
                </b-button>
                <div
                  v-if="!disableField('mid')"
                  style="text-align:right; color:#82868b;"
                >
                  <a
                    nohref
                    @click="deleteImage(carPermitImages['instalation_verification'].id)"
                  >
                    Installationsnachweis löschen
                  </a>
                </div>
              </div>
            </b-form-group>
            <b-form-group
              v-if="carData.flatRate==false"
              label="Nachweise für die Einzelladevorgänge*"
              label-for="Nachweise für die Einzelladevorgänge"
            >
              <small style="color: #5e5873;">
                Eine Dokumentation zu Einzelladevorgängen mit Datum, Zeitraum und kWh, die Sie aus der Ladestation auslesen (keine Fahrzeug Apps!). Laden Sie mit dem Antrag ein Beispiel hoch, nach Ablauf des Jahres können Sie dann die Einzelladevorgänge des ganzen Jahres hochladen.
              </small>
              <div
                v-if="!carPermitImages['evidence_individual_charging_processes'].id && carData.currentStatus <= 21"
              >
                <file-pond
                  ref="pondEEdit"
                  name="fileUpload"
                  label-idle="Wählen Sie eine Datei oder ziehen Sie es ins Feld. Unterstützte Dateiformate: JPG, PNG - Max Größe: 20MB"
                  allow-replace="true"
                  allow-revert="true"
                  accepted-file-types="application/pdf, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet"
                  :server="{
                    url: apiUrl,
                    process: {
                      url: `/file-repository/change/${tempFormUUID}/evidence_individual_charging_processes/${props.userIdProp}/${carData.bonusId}`,
                      method: 'POST',
                      headers: {
                        'Authorization': `Bearer ${accessToken}`
                      },
                    },
                    revert: {
                      url: `/file-repository/revert/${tempFormUUID}/evidence_individual_charging_processes/${props.userIdProp}`,
                      method: 'DELETE',
                      headers: {
                        'Authorization': `Bearer ${accessToken}`
                      },
                    },
                  }"
                  allow-file-encode="true"
                  allow-file-rename="true"
                  allow-image-resize="true"
                  image-resize-target-width="1280"
                  image-resize-target-height="720"
                  image-resize-mode="cover"
                  :file-rename-function="fileRenameFunction"
                  @processfile="processFile"
                  @removefile="removeFile"
                  style="margin-top: 8px"
                />
              </div>
              <div
                v-if="carPermitImages['evidence_individual_charging_processes'].id"
              >
                <b-button
                  variant="outline-secondary"
                  block
                  @click="previewImage(carPermitImages['evidence_individual_charging_processes'].id, carPermitImages['evidence_individual_charging_processes'].userId, carPermitImages['evidence_individual_charging_processes'].fileContentType)"
                >
                  <feather-icon
                    v-if="carPermitImages['evidence_individual_charging_processes'].fileContentType == 'application/pdf'"
                    icon="FileTextIcon"
                  />
                  <feather-icon
                    v-else-if="carPermitImages['evidence_individual_charging_processes'].fileContentType == 'text/csv'"
                    icon="FileTextIcon"
                  />
                  <feather-icon
                    v-else-if="carPermitImages['evidence_individual_charging_processes'].fileContentType.includes('spreadsheet') || carPermitImages['evidence_individual_charging_processes'].fileContentType.includes('excel')"
                    icon="FileTextIcon"
                  />
                  <feather-icon
                    v-else
                    icon="CameraIcon"
                  />
                  Nachweise für die Einzelladevorgänge vorschau
                </b-button>
                <div
                  v-if="!disableField('mid')"
                  style="text-align:right; color:#82868b;"
                >
                  <a
                    nohref
                    @click="deleteImage(carPermitImages['evidence_individual_charging_processes'].id)"
                  >
                    Nachweise für die Einzelladevorgänge löschen
                  </a>
                </div>
              </div>
            </b-form-group>
            <br>
            <hr>
            <!-- Form Actions -->
            <!--
            <b-alert
              :variant="partnerData.colorScheme"
              :show="!canUpdateCar"
            >
              <div class="alert-body">
                <span style="font-size:13px;">Bitte vervollständigen Sie Ihre Daten bevor Sie Daten speichern können.</span>
              </div>
            </b-alert>
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                block
                @click="resetCarData();hide();"
              >
                Schließen
              </b-button>
              &nbsp;
              <br>
              &nbsp;
              <b-button
                v-if="carData.currentStatus == 10"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                :disabled="!canUpdateCar"
                :variant="partnerData.colorScheme"
                type="button"
                @click="onSubmit();"
              >
                Speichern
              </b-button>
              <b-button
                v-if="carData.currentStatus == 15"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                :disabled="!canUpdateCar"
                :variant="partnerData.colorScheme"
                type="button"
                @click="onSubmit();"
              >
                Daten ändern
              </b-button>
              <b-button
                v-if="carData.currentStatus === 20 || carData.currentStatus === 21"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                block
                type="button"
                :disabled="!canUpdateCar"
                :variant="partnerData.colorScheme"
                @click="onSubmit();hide();"
              >
                Daten bestätigen
              </b-button>
            </div>
            <br>
            <br>
            <b-alert
              v-if="carData.currentStatus == 15"
              variant="secondary"
              show
            >
              <div class="alert-body">
                <span><strong>Info: </strong> änderung Felder mit * generiert neue Vertrag!</span>
              </div>
            </b-alert>
            -->
          </b-form>
        </validation-observer>
        <b-modal
          v-model="modalPreviewImage"
          ok-variant="outline-primary"
          title="Vorschau"
          ok-title="Schließen"
          ok-only
          size="xl"
        >
          <div
            v-if="loadingPreviewFile === true"
            style="text-align: center;"
          >
            <b-spinner /><br>
            Wird geladen ...
          </div>
          <div
            style="text-align: center; background-color:black;"
          >
            <iframe
              v-if="previewFileContentType == 'application/pdf'"
              :src="previewFile"
              style="width: 100%;min-height: 85vh; border: none;"
            >
              Oops! an error has occurred.
            </iframe>
            <b-img
              v-else
              :src="previewFile"
              style="max-height: 85vh;"
              fluid
            />
          </div>
        </b-modal>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSelect, BFormDatepicker, BTooltip, BModal, BSpinner, BImg, BAlert, BFormCheckbox, BFormRadio, BFormRadioGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { base64StringToBlob } from 'blob-util'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  ref,
  onUnmounted,
  watch,
  computed,
} from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { v4 as uuidv4 } from 'uuid'

import vueFilePond from 'vue-filepond'
//  Import plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import FilePondPluginFileRename from 'filepond-plugin-file-rename'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'

import Cleave from 'vue-cleave-component'
import { numberFormat } from '@core/utils/localSettings'

import { $authUrl } from '@microServicesConfig'

import store from '@/store'
import sidebarStoreModel from './sidebarStoreModule'

//  Import filepond styles
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    BFormDatepicker,
    BTooltip,
    BModal,
    BSpinner,
    BImg,
    BAlert,
    BFormCheckbox,
    BFormRadio,
    BFormRadioGroup,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditCarActive',
    event: 'update:is-edit-car-active',
  },
  props: {
    isEditCarActive: {
      type: Boolean,
      required: true,
    },
    editCarId: {
      type: String,
      required: true,
    },
    userIdProp: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      options: {
        number: numberFormat,
      },
      useTypes: [
        { value: '1', text: 'Privatfahrzeug' },
        { value: '2', text: 'Firmenwagen' },
      ],
      connectorTypes: [
        { value: '1', text: 'Typ 1' },
        { value: '2', text: 'Typ 2' },
        { value: '3', text: 'CCS' },
        { value: '4', text: 'CHAdeMO' },
      ],
      apiUrl: $authUrl,
    }
  },
  methods: {
    fileRenameFunction(file) {
      return `${uuidv4()}${file.extension}`
    },
  },
  //  setup(props, { emit, refs }) {
  setup(props, { emit, refs, root }) {
    const APP_SIDEBAR_STORE_MODULE_NAME = 'app-sidebar'

    // Register module
    if (!store.hasModule(APP_SIDEBAR_STORE_MODULE_NAME)) store.registerModule(APP_SIDEBAR_STORE_MODULE_NAME, sidebarStoreModel)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_SIDEBAR_STORE_MODULE_NAME)) store.unregisterModule(APP_SIDEBAR_STORE_MODULE_NAME)
    })

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileRename, FilePondPluginImageResize, FilePondPluginImageTransform)

    const authUser = window.$cookies.get('userData')
    const accessToken = window.$cookies.get('accessToken')
    const toast = useToast()
    const dataDisabled = ref(true)
    const canUpdateCar = ref(false)

    // UI
    const partner = ref(window.$cookies.get('partner'))
    const partnerData = ref(window.$cookies.get('partnerData'))
    const getHrefStyle = () => `color:var(--${partnerData.value.colorScheme})`

    const isMobile = ref(true)
    const currentBreakPointVal = ref(store.getters['app/currentBreakPoint'])
    const sidebarMinWidth = ref('95%')
    const bodyPadding = ref('0')
    if (store.getters['app/currentBreakPoint'] === 'xl' || store.getters['app/currentBreakPoint'] === 'lg') {
      isMobile.value = false
      sidebarMinWidth.value = '700px'
      bodyPadding.value = '50'
    }

    const currentBreakPoint = computed(() => store.getters['app/currentBreakPoint'])
    watch(currentBreakPoint, val => {
      currentBreakPointVal.value = val
      if (val === 'xl' || val === 'lg') {
        isMobile.value = false
        sidebarMinWidth.value = '700px'
        bodyPadding.value = '50'
      } else {
        isMobile.value = true
        sidebarMinWidth.value = '95%'
        bodyPadding.value = '0'
      }
    })

    const modalPreviewImage = ref(false)
    const previewFileContentType = ref('')
    const previewFile = ref('')
    const loadingPreviewFile = ref(false)
    const userId = ref('')

    const currentYear = new Date().getFullYear().toString()
    const dateFrom = `${currentYear}-01-01`
    const dateTo = `${currentYear}-12-31`

    const blankCarData = {
      carTitle: '',
      vin: '',
      useType: 1,
      address: null,
      extraCharhingInfo: '',
      connectorType: 2,
      dateFrom,
      dateTo,
      flatRate: null,
      chargingStationId: '',
      chargingStationPorts: null,
      chargingStationPower: null,
      chargingStationManufacturer: '',
    }

    const minDate = new Date(`${currentYear}-01-01`)
    const maxDate = new Date(`${currentYear}-12-31`)

    const carPermitImages = ref({
      permit_side_a: {}, permit_side_b: {}, instalation_verification: {}, mid: {}, evidence_individual_charging_processes: {},
    })
    const tempFormUUID = ref(uuidv4())

    const carData = ref(JSON.parse(JSON.stringify(blankCarData)))
    const resetCarData = () => {
      carData.value = JSON.parse(JSON.stringify(blankCarData))
      tempFormUUID.value = uuidv4()

      if (typeof (refs.pondAEdit) !== 'undefined') {
        refs.pondAEdit.removeFiles()
        refs.pondBEdit.removeFiles()
        refs.pondCEdit.removeFiles()
        refs.pondDEdit.removeFiles()
      }
    }

    const addressList = ref([])
    addressList.value.push({ value: null, text: 'Bitte auswählen' })
    addressList.value.push({ value: '-1', text: 'neue Adresse' })

    const onSubmit = () => {
      refs.refEditCarFormObserver.validate().then(success => {
        if (success) {
          // canUpdateCar.value = true
          carData.value.tempId = tempFormUUID.value
          store.dispatch(`${APP_SIDEBAR_STORE_MODULE_NAME}/update`, { id: userId.value, bonusId: carData.value.bonusId, carData: carData.value })
            .then(response => {
              if (response.status === 200) {
                //  update to prevent sidebar on list reload
                authUser.newCarVin = carData.value.vin
                //  window.$cookies.set('userData', authUser)
                window.$cookies.set('userData', authUser, Infinity, null, null, true, 'None')
                //  getCarDetail()
                emit('update:is-edit-car-active', false)
                store.commit('app-home/updateCarUpdatedAt')
              } else if (response.status === 406) {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Fehler',
                    text: 'Bitte Zulassungsschein hochladen',
                    icon: 'AlertTriangleIcon',
                    variant: 'warning',
                  },
                })
              } else {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Fehler',
                    text: response.data.payload.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'warning',
                  },
                })
              }
            })
            .catch(error => {
              if (error.response.status === 406) {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Fehler',
                    text: 'Bitte Zulassungsschein hochladen',
                    icon: 'AlertTriangleIcon',
                    variant: 'warning',
                  },
                })
              } else {
                toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Fehler',
                    icon: 'AlertTriangleIcon',
                    variant: 'warning',
                    text: 'Bitte überprüfen Sie ihren Daten.',
                  },
                })
              }
            })
        }
      })
    }

    const getFile = (fileId, userId) => {
      previewFileContentType.value = ''
      previewFile.value = ''
      loadingPreviewFile.value = true
      store.dispatch(`${APP_SIDEBAR_STORE_MODULE_NAME}/getFile`, { fileId, userId })
        .then(response => {
          const blob = base64StringToBlob(response.data, response.headers['content-type'])
          const fileURL = URL.createObjectURL(blob)

          previewFileContentType.value = response.headers['content-type']
          previewFile.value = fileURL
          loadingPreviewFile.value = false

          if (response.headers['content-type'].includes('text/csv') || response.headers['content-type'].includes('spreadsheet') || response.headers['content-type'].includes('excel')) {
            window.location.href = fileURL
          }
        })
        .catch(() => {
          loadingPreviewFile.value = false
        })
    }

    const previewImage = (fileId, userId, fileContentType) => {
      if (fileContentType == 'text/csv' || fileContentType == 'application/vnd.ms-excel' || fileContentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || fileContentType == 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet') {
        getFile(fileId, userId)
      } else {
        modalPreviewImage.value = true
        getFile(fileId, userId)
      }
    }

    const getCarDetail = () => {
      dataDisabled.value = true
      //  store.dispatch(`${APP_SIDEBAR_STORE_MODULE_NAME}/getCarDetail`, { userId: props.userIdProp, carId: props.editCarId })
      store.dispatch(`${APP_SIDEBAR_STORE_MODULE_NAME}/getCarDetail`, { userId: props.userIdProp, carId: props.editCarId })
        .then(response => {
          carData.value.carTitle = response.data.car.title
          carData.value.vin = response.data.car.vin
          carData.value.connectorType = response.data.car.connectorType
          carData.value.useType = response.data.bonus.useType

          carData.value.currentStatus = 10
          if (typeof response.data.bonus.currentStatus !== 'undefined') {
            carData.value.currentStatus = response.data.bonus.currentStatus
          }

          if (carData.value.currentStatus < 30) {
            dataDisabled.value = false
          } else {
            dataDisabled.value = false
          }

          carData.value.bonusId = response.data.bonus.id
          userId.value = response.data.bonus.userId

          carData.value.dateFrom = response.data.bonus.dateFrom
          carData.value.dateTo = response.data.bonus.dateTo
          carData.value.dateTo = response.data.bonus.dateTo

          //  carData.value.flatRate = response.data.bonus.flatRate
          //  override end of season 2023 as not all have the data -- remove this after 2023 year change
          carData.value.flatRate = null
          if (response.data.bonus.flatRate === true) {
            carData.value.flatRate = true
          } else if (response.data.bonus.flatRate === false) {
            if (response.data.bonus.chargingStationManufacturer != null && response.data.bonus.chargingStationManufacturer.length > 0) {
              carData.value.flatRate = false
            }
          }

          carData.value.chargingStationId = response.data.bonus.chargingStationId
          carData.value.chargingStationPorts = response.data.bonus.chargingStationPorts
          carData.value.chargingStationPower = response.data.bonus.chargingStationPower
          carData.value.chargingStationManufacturer = response.data.bonus.chargingStationManufacturer

          carData.value.powerUsed = response.data.bonus.powerUsed
          carData.value.extraCharhingInfo = response.data.bonus.extraCharhingInfo

          carData.value.address = response.data.address.id

          const addressString = `${response.data.address.street}, ${response.data.address.zip} ${response.data.address.city}`

          store.dispatch(`${APP_SIDEBAR_STORE_MODULE_NAME}/getAddressList`, { id: props.userIdProp })
            .then(responseAddress => {
              for (let i = 0; i < responseAddress.data.length; i += 1) {
                const addressStringDB = `${responseAddress.data[i].street}, ${responseAddress.data[i].zip} ${responseAddress.data[i].city}`
                let addressId = responseAddress.data[i].id
                if (addressString === addressStringDB) {
                  addressId = response.data.address.id
                  //  addressId = responseAddress.id
                }

                const tmpObj = {
                  value: addressId,
                  text: addressStringDB,
                }

                addressList.value.push(tmpObj)
              }
            })

          // . image preview
          carPermitImages.value.permit_side_a = {}
          carPermitImages.value.permit_side_b = {}
          carPermitImages.value.mid = {}
          carPermitImages.value.instalation_verification = {}
          carPermitImages.value.evidence_individual_charging_processes = {}
          for (let i = 0; i < response.data.files.length; i += 1) {
            if ((response.data.files[i].fileType === 'permit_side_a') || (response.data.files[i].fileType === 'permit_side_b') || (response.data.files[i].fileType === 'mid') || (response.data.files[i].fileType === 'instalation_verification') || (response.data.files[i].fileType === 'evidence_individual_charging_processes')) {
              carPermitImages.value[response.data.files[i].fileType].id = response.data.files[i].id
              carPermitImages.value[response.data.files[i].fileType].userId = response.data.files[i].userId
              carPermitImages.value[response.data.files[i].fileType].vin = response.data.files[i].vin
              carPermitImages.value[response.data.files[i].fileType].fileName = response.data.files[i].fileName
              carPermitImages.value[response.data.files[i].fileType].fileContentType = response.data.files[i].fileContentType
            }
          }

          setTimeout(() => {
            // eslint-disable-next-line no-use-before-define
            processFile()
          }, 500)
        })
    }

    const deleteImage = fileId => {
      root.$bvModal
        .msgBoxConfirm('Zulassungsschein löschen?', {
          title: 'Löschen',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            store.dispatch(`${APP_SIDEBAR_STORE_MODULE_NAME}/deleteImage`, { fileId })
              .then(() => {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Erfolg',
                    text: 'Status gespeichert.',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                getCarDetail()
              })
              .catch(() => {
                console.log('error')
              })
          }
        })
    }

    const confirmData = () => {
      console.log('confirm data', carData.value.bonusId)
      store.dispatch(`${APP_SIDEBAR_STORE_MODULE_NAME}/confirmData`, { bonusId: carData.value.bonusId, userId: props.userIdProp })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Erfolg',
              text: ' Daten bestätigt.',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          store.commit('app-home/updateCarUpdatedAt')
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Fehler',
              icon: 'AlertTriangleIcon',
              variant: 'warning',
            },
          })
        })
    }
    const processFile = () => {
      canUpdateCar.value = false
      let counter = 0

      console.log('carPermitImages', carPermitImages)
      //  console.log('carPermitImages.mid.id', carPermitImages.mid.id)
      console.log('carData', carData)

      if ((typeof carPermitImages.value.permit_side_a.id !== 'undefined') && (refs.pondAEdit.getFiles().length > 0)) {
        counter += 1
      }

      if ((typeof carPermitImages.value.permit_side_b.id !== 'undefined') && (refs.pondBEdit.getFiles().length > 0)) {
        counter += 1
      }

      if (carData.value.flatRate === true) {
        if (counter === 2) {
          canUpdateCar.value = true
        }
      } else {
        if ((typeof carPermitImages.value.mid.id !== 'undefined') && (refs.pondCEdit.getFiles().length > 0)) {
          counter += 1
        }

        if ((typeof carPermitImages.value.instalation_verification.id !== 'undefined') && (refs.pondDEdit.getFiles().length > 0)) {
          counter += 1
        }

        if (counter === 4) {
          canUpdateCar.value = true
        }
      }
    }

    const removeFile = () => {
      canUpdateCar.value = false
    }

    const getLabel = field => {
      if (field === 'vin') {
        if (carData.value.currentStatus === 15) {
          return 'Fahrzeugidentifikationsnummer *'
        }
        return 'Fahrzeugidentifikationsnummer'
      }

      if (field === 'address') {
        if (carData.value.currentStatus === 15) {
          return 'Ladeadresse *'
        }
        return 'Ladeadresse'
      }

      if (field === 'permit_side_a') {
        if (carData.value.currentStatus === 15) {
          return 'Zulassungsschein Vorderseite *'
        }
        return 'Zulassungsschein Vorderseite'
      }

      if (field === 'permit_side_b') {
        if (carData.value.currentStatus === 15) {
          return 'Zulassungsschein Rückseite *'
        }
        return 'Zulassungsschein Rückseite'
      }

      if (field === 'dateFrom') {
        if (carData.value.currentStatus === 15) {
          return 'Startzeitpunkt *'
        }
        return 'Startzeitpunkt'
      }

      if (field === 'dateTo') {
        if (carData.value.currentStatus === 15) {
          return 'Endzeitpunkt *'
        }
        return 'Endzeitpunkt'
      }

      return ''
    }

    const disableField = field => {
      if ((carData.value.currentStatus === 10) || (carData.value.currentStatus === 15)) {
        return false
      }

      if (carData.value.currentStatus === 20 || carData.value.currentStatus === 21) {
        return ['vin', 'dateFrom', 'permit_side_a', 'permit_side_b'].some(str => str.includes(field))
      }

      return true
    }

    watch(() => props.isEditCarActive, (selection, prevSelection) => {
      if (selection === true) {
        console.log('trigger getcardetils')
        setTimeout(() => {
          // eslint-disable-next-line no-use-before-define
          getCarDetail()
        }, 500)
      }
    })

    return {
      props,
      carData,
      onSubmit,
      resetCarData,
      getCarDetail,

      FilePond,
      addressList,
      tempFormUUID,
      accessToken,
      authUser,
      carPermitImages,
      getFile,
      previewImage,
      modalPreviewImage,
      previewFileContentType,
      previewFile,
      loadingPreviewFile,
      confirmData,

      partner,
      partnerData,
      getHrefStyle,
      isMobile,
      currentBreakPointVal,
      sidebarMinWidth,
      bodyPadding,
      minDate,
      maxDate,
      dataDisabled,
      deleteImage,
      canUpdateCar,
      removeFile,
      processFile,
      getLabel,
      disableField,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-car-active {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

/*
.b-sidebar.sidebar-lg {
    min-width: 95%;
}
*/
</style>
